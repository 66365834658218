import styled, { css } from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';
import { paddingY } from '../../styles/atoms/spacing';

export const AnimationContainer = styled.div`
  position: relative;
`;

export const customHeadingStyles = animationInHero => css`
  ${animationInHero &&
    css`
      min-height: 660px;
      justify-content: center;
    `}
`;

export const customHeadingInnerStyles = (
  animationInHero,
  removeBottomPadding
) => css`
  && {
    padding-bottom: ${!animationInHero && removeBottomPadding ? '0' : ''};
    padding-top: 48px;

    ${atMinWidth.md`
      padding-top: 139px;
    `}

    ${atMinWidth.lg`
      padding-top: 147px;
    `}
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${atMinWidth.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${atMinWidth.lg`
    flex-direction: row;
    align-items: flex-start;

    & > div {
      flex: 1 0 50%;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 60px;

  ${atMinWidth.lg`
    gap: 40vh;
    flex: 0 0 45%;
    ${paddingY(125, 1)}
  `}
`;

export const HeroLottieWrapper = styled.div`
  position: absolute;
  padding-top: 140px;
  top: 0;
  right: 0;
  height: 100%;
  width: 45%;
  z-index: 100;
`;

export const LottieWrapper = styled.div`
  position: sticky;
  top: 140px;
  margin-top: ${props => (props.includeMargin ? '140px' : '')};
  height: fit-content;
`;
